import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {TurnPlugin} from "vue-turnjs";
import "vue-turnjs/dist/vue-turnjs.esm.css";
import {store} from "@/store";

import './assets/app.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(TurnPlugin);
Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  store: store,
}).$mount('#app')
