<template>
  <div id="app">
    <b-container class="pt-5 px-4">
      <b-row align-v="center" align-h="around">
        <b-col cols="12" :md="this.$store.getters.bookExpanded ? 12 : 6" ref="book-container" class="px-0 py-5">
          <Viewer id="viewer"/>
        </b-col>
        <b-col cols="12" :md="this.$store.getters.bookExpanded ? 12 : 6" ref="title-container" class="p-3">
          <p :class="{ 'text-center': this.$store.getters.bookExpanded, 'text-left': !this.$store.getters.bookExpanded}"
             style="font-size: 3rem; font-weight: bold">ECHO CHAMBER</p>
          <p :class="{ 'text-center': this.$store.getters.bookExpanded, 'text-left': !this.$store.getters.bookExpanded}">
            "a yearly project of a random guy in his 24"
          </p>
          <div :class="{ 'text-center': this.$store.getters.bookExpanded, 'text-left': !this.$store.getters.bookExpanded}">
            <b-button-group>
              <b-button id="read-button" v-on:click="this.bookReadButtonClicked" variant="primary">Read Now
              </b-button>
              <b-dropdown right text="Get Copy" variant="outline-primary">
                <b-dropdown-item :href="`echochamberbook.pdf`" download>Download PDF</b-dropdown-item>
                <b-dropdown-item id="purchase-button" target="_blank" href="https://www.blurb.com/bookstore/invited/9456135/ee39285c6dd67ca7c99dd22eb680dd9d9f3c17a3">Purchase Hardcover</b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </div>
        </b-col>
      </b-row>
      <div class="row">
        <div class="col"><hr></div>
        <div class="col-auto">BACKGROUND</div>
        <div class="col"><hr></div>
      </div>
      <p style="color: darkgray">
        Echo Chamber is a yearly project made by <a href="https://www.justzht.com">Haotian Zheng</a>, as he collected all the post he made in 2021 across his Twitter, Instagram, Day One, and personal blog posts and consolidated into one book.
        You can read the <a href="https://www.justzht.com/echochamber-xu/">prelude</a> to get an idea of why made this little lovely book. Made with Vue and TurnJs.
      </p>
      <b-popover target="purchase-button" triggers="hover">
        Blurb is responsible for the handling and fees. The  <b>price is only used for covering the production cost</b>, there is no benefit for me in purchasing the physical book.
      </b-popover>
      <b-popover target="read-button" triggers="hover" placement="top">
        Use arrow key or page control buttons to navigate the book.
      </b-popover>
    </b-container>
  </div>
</template>

<script>
import Viewer from "@/components/Viewer";

export default {
  name: 'App',
  components: {
    Viewer
  },
  data() {
    return {
      bookContainerResizeObserver: null
    }
  },
  created() {
    window.addEventListener("resize", this.windowResized);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResized);
  },
  mounted() {
    this.windowResized();
    this.bookContainerResized();
    this.bookContainerResizeObserver = new ResizeObserver(this.bookContainerResized)
    this.bookContainerResizeObserver.observe(this.$refs["book-container"])
  },
  beforeDestroy() {
    this.bookContainerResizeObserver.unobserve(this.$refs["book-container"])
  },
  methods: {
    bookContainerResized: function () {
      this.$store.commit('updateBookContainerWidth', this.$refs["book-container"].getBoundingClientRect().width);
    },
    bookReadButtonClicked: function () {
      this.$store.commit('updateBookCurrentPage', 3)
    },
    windowResized: function () {
      this.$store.commit('updateWindowWidth', window.innerWidth)
    }
  },
  computed: {
    bookShowPageControl: {
      get () {
        return this.$store.state.bookShowPageControl
      },
      set (value) {
        this.$store.commit('updateBookShowPageControl', value)
      }
    }
  },
  watch: {
  }
}
</script>

<style>
.row [class*='col-'] {
  transition: all 0.4s ease-in-out;
}
.book-container {

}
.title-container {

}
</style>

