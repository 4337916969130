<template>
  <div>
    <fw-turn :style="bookStyle" ref="flipbook" class="flipbook" :options="options">
      <img class="hard" :src="`pages/Cover-Left.jpg`" :style="coverStyle"/>
      <img class="" :src="`pages/S-Page-0.jpg`" :style="coverStyle"/>
      <img v-for="i in Array.from({length: 69}, (_, i) => i + 1)" :key="i"
           class=""
           :src="
          `pages/S-Page-${i}.jpg`
        "
           :alt="`page ${i}`"
           :style="pageStyle"
      />
      <div :style="coverStyle"></div>
      <img class="" :src="`pages/S-Page-0.jpg`" :style="coverStyle"/>
      <img class="hard" :src="`pages/Cover-Right.jpg`" :style="coverStyle"/>
    </fw-turn>
    <b-button-toolbar v-if="this.$store.getters.bookExpanded" class="py-3" justify>
      <b-button-group class="mx-1">
        <b-button v-on:click="bookPrevious" variant="outline">&lsaquo;</b-button>
      </b-button-group>
      <b-button variant="outline" disabled>{{this.$store.state.bookCurrentPage}} / {{this.$store.state.bookTotalPage}}</b-button>
      <b-button-group class="mx-1">
        <b-button v-on:click="bookNext" variant="outline">&rsaquo;</b-button>
      </b-button-group>
    </b-button-toolbar>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  // ratio 1.5102040816
  // default page height 666 width 441
  // extended page height 696 width 461
  name: "Viewer",
  props: {
  },
  created() {
    window.addEventListener('keydown', this.receiveKeyCommand);
  },
  destroyed() {
    window.removeEventListener('keydown', this.receiveKeyCommand);
  },
  mounted() {
  },
  computed: {
    turnJs: function () {
      return $(this.$refs.flipbook.selector);
    },
    bookCurrentPage: function () {
      return this.$store.state.bookCurrentPage
    },
    bookPageMode: function () {
      return this.$store.getters.bookPageMode
    },
    bookContainerWidth: function () {
      return this.$store.state.bookContainerWidth
    },
    bookContainerHeight: function () {
      return this.bookContainerWidth / (this.bookPageMode === 'single' ? 1.0 : 2.0) * 1.5102040816
    },
    coverStyle: function () {
      return {
        'background': 'black'
      }
    },
    pageStyle: function () {
      return {
      }
    },
    bookStyle: function () {
      return {
      }
    }
  },
  methods: {
    bookNext() {
      this.turnJs.turn('next')
    },
    bookPrevious() {
      this.turnJs.turn('previous')
    },
    receiveKeyCommand(e) {
      if (e.keyCode === 39) {
        // right
        this.turnJs.turn('next');
      } else if (e.keyCode === 37) {
        // left
        this.turnJs.turn('previous');
      }
    }
  },
  watch: {
    bookContainerWidth: function () {
      this.turnJs.turn('size', this.bookContainerWidth, this.bookContainerHeight)
      this.turnJs.turn('display', this.bookPageMode)
    },
    bookCurrentPage: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.turnJs.turn('page', newVal)
      }
    },
    bookPageMode: function (newVal) {
      this.turnJs.turn('display', newVal)
      this.turnJs.turn('size', this.bookContainerWidth, this.bookContainerHeight)
    }
  },
  data() {
    let vm = this;
    return {
      options: {
        display: 'double',
        acceleration: true,
        autoCenter: true,
        elevation: 100,
        when: {
          turned: function (e, page) {
            vm.$store.commit('updateBookCurrentPage', page)
          }
        }
      }
    };
  }
}
</script>

<style>
.flipbook .shadow {
  box-shadow:0 0 10px #999;
  /*background: black;*/
}
</style>
