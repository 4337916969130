import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex)
export const store = new Vuex.Store({
    state: {
        bookCurrentPage: 1,
        bookTotalPage: 74,
        bookContainerWidth: 100,
        windowWidth: 100,
    },
    getters: {
        bookExpanded: (state, getters) => {
            if (getters.bookPageMode === "double") {
                return state.bookCurrentPage > 1 && state.bookCurrentPage < state.bookTotalPage
            } else {
                return true
            }
        },
        bookPageMode: state => {
            return state.windowWidth < 576 ? "single" : "double"
        },
        bookOptions: (state, getters) => {
            return {
                display: getters.bookPageMode,
                acceleration: true,
                autoCenter: true,
                elevation: 100,
                width: state.bookContainerWidth,
                height: state.bookContainerWidth / (getters.bookPageMode === "double" ? 2.0 : 1.0) * 1.5102040816,
                when: {
                    turned: function (e, page) {
                        console.log("turned " + page)
                        store.commit('updateBookCurrentPageActual', page)
                    }
                }
            }
        }
    },
    mutations: {
        updateBookCurrentPage(state, newPage) {
            if (state.bookCurrentPage === newPage) { return; }
            state.bookCurrentPage = newPage
        },
        updateBookContainerWidth(state, newWidth) {
            if (state.bookContainerWidth === newWidth) { return; }
            state.bookContainerWidth = newWidth
        },
        updateWindowWidth(state, newWidth) {
            if (state.windowWidth === newWidth) { return; }
            state.windowWidth = newWidth
        }
    }
})